import React from "react";
import {graphql} from 'gatsby';
import PostContent from './Post/';
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const BlogPostFr = (props) => {
  return (
    <Layout 
    lang="fr-CA" 
    switchLink={'/en/blog/' + props.pageContext.previous.slug}
    switchText='EN' 
    slider={false} 
    title={props.data.contentfulArticleDeBlogue.title}>
      <Seo 
        title={props.data.contentfulArticleDeBlogue.title}
        description={props.data.contentfulArticleDeBlogue.excerpt}
       />
       <PostContent lang="fr-CA" data={props.data.contentfulArticleDeBlogue} latests={props.data.allContentfulArticleDeBlogue.edges}/>
    </Layout>
  )
}

export default BlogPostFr

export const pageQuery = graphql`
  query BlogPostBySlugFr($slug: String!) {
    contentfulArticleDeBlogue( slug: { eq: $slug }) {
      title
      createdAt(locale: "fr", formatString: "Do MMMM YYYY")
      excerpt
      content {
        raw
        references {
          ... on ContentfulAsset {
              contentful_id
              __typename
              gatsbyImageData(layout: CONSTRAINED)
            }
        }
      }
      headerImg {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    allContentfulArticleDeBlogue(limit: 2, filter: {node_locale: {eq: "fr-CA"}}) {
      edges {
        node {
          title
          createdAt(locale: "fr", formatString: "Do MMMM YYYY")
          excerpt
          slug
          headerImg {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`